<template>
  <a-modal
    title="新建"
    :width="1440"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="手动增添调拨子单">
              <vxe-toolbar>
                <template v-slot:buttons>
                  <vxe-button @click="handleAdd">新增</vxe-button>
                  <vxe-button @click="$refs.xTable.removeCheckboxRow()">删除选中</vxe-button>
                  <!--                  <vxe-button @click="importItems">通过台账导入</vxe-button>-->
                </template>
              </vxe-toolbar>
              <vxe-table
                border
                resizable
                show-overflow
                ref="xTable"
                :data="tableData"
                :toolbarConfig="{ perfect: false}"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="bonded_goods" title="商品" :edit-render="{name: 'select', attrs: {disabled: true}, options: bonded_goods_List, props: {clearable: true}, events: {change: goodsChangeEvent}}"></vxe-table-column>
                <vxe-table-column field="bonded_commodity" title="商品批次" :edit-render="{name: 'select', attrs: {disabled: true}, options: bonded_commodity_List, props: {clearable: true}, events: {change: commodityChangeEvent}}"></vxe-table-column>
                <vxe-table-column field="out_warehouse_space" title="出库仓位" :edit-render="{name: 'select', attrs: {disabled: true},options: warehouse_bonded_commodity_List, props: {clearable: true}, events: {change: outwareHouseChangeEvent}}"></vxe-table-column>
                <!--                <vxe-table-column field="out_cargo_space_id" title="出库仓位" :edit-render="{name: 'select',options: out_cargo_space_List, props: {clearable: true}}"></vxe-table-column>-->
                <vxe-table-column field="count" title="数量" :edit-render="{name: 'input', attrs: {disabled: true}, defaultValue: ''}"></vxe-table-column>
                <vxe-table-column field="in_warehouse" title="入库仓库" :edit-render="{name: 'select', attrs: {disabled: true},options: in_warehouse_List, props: {clearable: true}, events: {change: inwareHouseChangeEvent}}"></vxe-table-column>
                <vxe-table-column field="in_cargo_space" title="入库仓位" :edit-render="{name: 'select', attrs: {disabled: true},options: in_cargo_space_List, props: {clearable: true}}"></vxe-table-column>
              </vxe-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <a-modal
      :footer="null"
      ref="addmodel"
      v-model="addvisible"
      v-if="addvisible"
      title="新增"
      :width="1440" >
      <a-form-model ref="ruleForm" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-model-item label="商品">
          <a-select v-model="addForm.bonded_goods_id" placeholder="请选择" @change="goodsChangeEvent" label-in-value>
            <a-select-option v-for="item in bonded_goods_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="保税商品">
          <a-select
            v-model="addForm.bonded_commodity_id"
            placeholder="请输入保税商品名称"
            @change="commodityChangeEvent"
            label-in-value>
            <a-select-option v-for="item in bonded_commodity_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="出库仓位">
          <a-select v-model="addForm.out_warehouse_space_id" placeholder="请选择" @change="outwareHouseChangeEvent" label-in-value>
            <a-select-option v-for="item in warehouse_bonded_commodity_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="出库数量">
          <a-input-number :min="0" :max="allCount" :step="1" v-model="addForm.count"/>
          可出库数量：{{ allCount }}
        </a-form-model-item>
        <a-form-model-item label="入库仓库">
          <a-select v-model="addForm.in_warehouse_id" placeholder="请选择" @change="inwareHouseChangeEvent" label-in-value>
            <a-select-option v-for="item in in_warehouse_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="入库仓位">
          <a-select v-model="addForm.in_cargo_space_id" placeholder="请选择" @change="in_cargo_spaceChangeEvent" label-in-value>
            <a-select-option v-for="item in in_cargo_space_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 4, offset: 12 }">
          <a-button type="primary" @click="submitForm('ruleForm')">
            确定
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>

<script>
import 'vxe-table/lib/style.css'
import pick from 'lodash.pick'
import { bonded_goods_list } from '@/api/bonded_goods'
import { bonded_commodity_list } from '@/api/bonded_commodity'
import { bonded_warehouse_list, bonded_warehouse_bonded_commodity_list } from '@/api/bonded_warehouse'
import { bonded_cargo_space_list } from '@/api/bonded_cargo_space'

// 表单字段
const fields = ['id', 'bill_id', 'corporation_id', 'amount', 'account', 'bank',
  'number', 'img', 'status', 'category', 'corporation']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      rules: {
        bonded_goods_id: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      addForm: {},
      labelCol: { span: 4, offset: 4 },
      wrapperCol: { span: 10 },
      addvisible: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      list: [],
      allCount: 0,
      bonded_goods_List: [],
      bonded_commodity_List: [],
      warehouse_bonded_commodity_List: [],
      in_cargo_space_List: [],
      out_cargo_space_List: [],
      out_warehouse_List: [],
      in_warehouse_List: [],
      current_obj: {},
      tableData: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.get_bonded_goods_list()
    this.get_out_warehouse_list()
    this.get_in_warehouse_list()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    submitForm (formName) {
      console.log(this.$refs.ruleForm)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.insertEvent(this.addForm)
          this.addvisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleOk () {
      console.log(11)
    },
    handleAdd () {
      this.addForm = {}
      this.addvisible = true
    },
    get_bonded_goods_list () {
      bonded_goods_list().then(({ data }) => {
        console.log(data)
        this.bonded_goods_List = data.entries || []
        this.bonded_goods_List = this.bonded_goods_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            disabled: false
          }
        })
        this.bonded_goods_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_bonded_commodity_list (obj) {
      bonded_commodity_list(obj).then(({ data }) => {
        console.log(data, 'commodity')
        this.bonded_commodity_List = data.entries || []
        this.bonded_commodity_List = this.bonded_commodity_List.map(item => {
          return {
            label: item.batch,
            value: item.id,
            inbound_count: item.inbound_count,
            disabled: false
          }
        })
        this.bonded_commodity_List.unshift({
            label: '请选择',
            value: -1,
            total_count: 0,
            disabled: false
          })
      })
    },
    get_in_cargo_space_list (obj) {
      bonded_cargo_space_list(obj).then(({ data }) => {
        console.log(data)
        this.in_cargo_space_List = data.entries || []
        this.in_cargo_space_List = this.in_cargo_space_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            disabled: false
          }
        })
        this.in_cargo_space_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_out_warehouse_list () {
      bonded_warehouse_list().then(({ data }) => {
        console.log(data, 'warehouse')
        this.out_warehouse_List = data.entries || []
        this.out_warehouse_List = this.out_warehouse_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            disabled: false
          }
        })
        this.out_warehouse_List.unshift({
            label: '请选择',
            value: -1,
            disabled: false
        })
      })
    },
    get_in_warehouse_list () {
      bonded_warehouse_list().then(({ data }) => {
        console.log(data, 'warehouse')
        this.in_warehouse_List = data.entries || []
        this.in_warehouse_List = this.in_warehouse_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            disabled: false
          }
        })
        this.in_warehouse_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_bonded_warehouse_bonded_commodity_list (obj) {
      bonded_warehouse_bonded_commodity_list(obj).then(({ data }) => {
        console.log(data)
        this.warehouse_bonded_commodity_List = data.entries || []
        this.warehouse_bonded_commodity_List = this.warehouse_bonded_commodity_List.map(item => {
          return {
            label: item.bonded_warehouse + '---' + item.bonded_cargo_space,
            value: item.id,
            bonded_warehouse_id: item.bonded_warehouse_id,
            bonded_cargo_space_id: item.bonded_cargo_space_id,
            all_count: item.count - item.freeze_count,
            disabled: false
          }
        })
        this.warehouse_bonded_commodity_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    goodsChangeEvent (obj) {
      this.allCount = 0
      this.addForm.bonded_commodity_id = { key: -1, label: '请选择' }
      this.addForm.out_warehouse_space_id = { key: -1, label: '请选择' }
      this.$forceUpdate()
      console.log(obj)
      this.addForm.bonded_goods = obj.label
      this.get_bonded_commodity_list({ bonded_goods_id: obj.key })
    },
    commodityChangeEvent (obj) {
      this.allCount = 0
      this.addForm.out_warehouse_space_id = { key: -1, label: '请选择' }
      this.$forceUpdate()
      this.addForm.bonded_commodity = obj.label
      this.get_bonded_warehouse_bonded_commodity_list({ bonded_commodity_id: obj.key })
    },
    outwareHouseChangeEvent (obj) {
      this.$forceUpdate()
      this.allCount = 0
      this.current_obj = this.warehouse_bonded_commodity_List.find((item) => {
        console.log(item.value)
        return item.value === obj.key
      })
      console.log(this.current_obj, obj.key, '---989')
      this.allCount = this.current_obj.all_count
      this.addForm.out_warehouse_space = obj.label
      this.addForm.out_cargo_space_id = { key: this.current_obj.bonded_cargo_space_id }
      this.addForm.out_warehouse_id = { key: this.current_obj.bonded_warehouse_id }
    },
    inwareHouseChangeEvent (obj) {
      this.addForm.in_warehouse = obj.label
      this.addForm.in_cargo_space_id = { key: -1, label: '请选择' }
      this.$forceUpdate()
      this.get_in_cargo_space_list({ bonded_warehouse_id: obj.key })
    },
    in_cargo_spaceChangeEvent (obj) {
      this.$forceUpdate()
      this.addForm.in_cargo_space = obj.label
    },
    ok () {
      let x = this.$refs.xTable.afterFullData
      x = x.map((item) => {
        Object.keys(item).forEach((i) => {
          if (i.indexOf('id') > -1) {
            item[i] = item[i].key
          }
        })
        return item
      })
      console.log(x)
      this.$emit('ok', x)
    },
    insertEvent (form) {
      console.log(form)
      this.allCount = 0
      const xTable = this.$refs.xTable
      xTable.insert(form)
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
